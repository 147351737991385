import { useEffect, useState } from "react"
import { Avatar } from "@mui/material"
import { useLazyGetEventParticipants } from "../../../../../app/data/promotion/promotionApi"
import { ViewsNames } from "../.."
import GradientBtn from "../../../../GradientBtn"
import SearchIcon from "../../../../../assets/svg/menu-icons-pack/search-icon.svg?react"
import AvatarFallback from "../../../../../assets/svg/header-icons-pack/avatar-empty-icon.svg?react"
import s from "./styles.module.scss"

type SearchUserProps = {
  onSetVeiew: (popupVeiew: ViewsNames) => void
  eventId: number
}

const SearchUser = ({ onSetVeiew, eventId }: SearchUserProps) => {
  const [trigger, { data }] = useLazyGetEventParticipants()
  const [searchedValue, setSearchedValue] = useState<string>("")
  const hadleGoToAddNewUser = () => {
    onSetVeiew(ViewsNames.AddNewUser)
  }

  useEffect(() => {
    if (eventId) {
      trigger({ eventId: eventId as number })
    }
  }, [eventId])

  const searchProducts = data?.content.filter((el) =>
    `${el.firstname.toLocaleLowerCase()}${el.lastname.toLocaleLowerCase()}`.includes(
      searchedValue.length > 0
        ? searchedValue.toLocaleLowerCase()
        : "$$$".toLocaleLowerCase(),
    ),
  )

  const renderParticipantsItems = () =>
    searchProducts?.map((participant) => (
      <li className={s.user_item} key={participant.phone_number}>
        <Avatar
          //src={participant.avatar}
          sx={{
            width: "50px",
            height: "50px",
            borderRadius: "50%",
          }}
        >
          <AvatarFallback className={s.user_avatar} />
        </Avatar>
        <div className={s.user_info}>
          <h3
            className={s.user_name}
          >{`${participant.firstname} ${participant.lastname}`}</h3>
          <p className={s.user_phone}>{participant.phone_number}</p>
        </div>
      </li>
    ))

  return (
    <div className={s.add_to_base_container}>
      <div className={s.add_to_base}>
        <div className={s.add_to_base_search}>
          <label className={s.search_label} htmlFor="search">
            <SearchIcon />
          </label>
          <input
            className={s.search_input}
            type="search"
            id="search"
            placeholder="Поиск участников"
            maxLength={50}
            onChange={(e) => setSearchedValue(e.currentTarget.value)}
          ></input>
        </div>

        <ul className={s.add_to_base_list}>
          {data?.content.length === 0
            ? "В базе нет ни одного пользователя"
            : renderParticipantsItems()}
        </ul>
      </div>
      <div className={s.buttons_block}>
        <GradientBtn
          title="Добавить нового человека в базу"
          handleAction={() => hadleGoToAddNewUser()}
          btnStyle={{
            height: "36px",
          }}
          textStyle={{
            fontFamily: "Manrope, sans-serif",
            fontSize: "12px",
            lineHeight: "16px",
            fontWeight: 400,
            letterSpacing: "0.4px",
          }}
        />
      </div>
    </div>
  )
}

export default SearchUser
