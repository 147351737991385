import { useEffect, useState } from "react"
import { createPortal } from "react-dom"

import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { setPromotionPopup } from "../../../app/data/popupSlice/popupSlice"
import {
  useAddNewPotentialParticipant,
  useCreateTicketForParticipant,
  useGetEventInvited,
  useLazyGetEventInvited,
  UserDataForAddToBaseParticipant,
} from "../../../app/data/promotion/promotionApi"

import SearchUser from "./views/search_user"
import AddNewUser from "./views/add_new_user"
import AddUserAccess from "./views/add_user_access"
import SendQr from "./views/send_qr"
import LocationSpinner from "../../profile_settings/location/location_spinner"
import BtnClose from "../../../assets/svg/close.svg?react"
import s from "./styles.module.scss"

export type PotentiaParticipantUserPreData = {
  firstname: string
  lastname: string
  phone_number: string
  telegram_username: string
}

export enum ViewsNames {
  SendQr = "SendQr",
  SearchUser = "SearchUser",
  AddNewUser = "AddNewUser",
  AccessNewUser = "AccessNewUser",
}

const AddToBaseModalTitles = {
  SendQr: "Отправить QR-код",
  AddNewUser: "Добавить человека в базу",
  SearchUser: "Поиск участников на:",
  AccessNewUser: "Подтвердите добавление",
}

const AddToBaseModal = () => {
  const currentEventData = useAppSelector(
    (state) => state.popupsState.promotionPopup,
  )
  const dispatch = useAppDispatch()

  const [
    addParticipant,
    {
      isSuccess: addedPaticipantSuccess,
      isLoading: addedPaticipantLoad,
      data: addedPaticipantId,
    },
  ] = useAddNewPotentialParticipant()
  const [
    getTicketUrl,
    { isSuccess: ticketSuccess, isLoading: ticketIsLoad, data: ticketUrl },
  ] = useCreateTicketForParticipant()

  const [view, setView] = useState<ViewsNames>(ViewsNames.SearchUser)

  const [addedUser, setAddedUser] = useState<UserDataForAddToBaseParticipant>({
    firstname: "",
    lastname: "",
    phone_number: "",
    telegram_username: "",
    event_id: currentEventData?.id as number,
  })

  useEffect(() => {
    if (addedPaticipantSuccess) {
      getTicketUrl({
        event_id: addedUser.event_id,
        invited_via_telegram: true,
        invited_via_whatsapp: true,
        potential_participant_id: addedPaticipantId,
      })
      setAddedUser({
        ...addedUser,
        event_id: 0,
        firstname: "",
        lastname: "",
        phone_number: "",
        telegram_username: "",
      })
    }
  }, [addedPaticipantId])

  const handleSetAddedUser = (
    currentKey: keyof PotentiaParticipantUserPreData,
    targetValue: string,
  ) => setAddedUser({ ...addedUser, [currentKey]: targetValue })

  const handleSetView = (typeofView: ViewsNames) => {
    setView(typeofView)
  }

  const onSubmitAddParticipant = (
    participantData: UserDataForAddToBaseParticipant,
  ) => {
    addParticipant({
      ...participantData,
      phone_number: addedUser.phone_number.slice(1),
    })
    setAddedUser({ ...addedUser, event_id: currentEventData?.id as number })
  }

  const renderCurrentView = () =>
    view === ViewsNames.SearchUser ? (
      <SearchUser
        eventId={currentEventData?.id as number}
        onSetVeiew={handleSetView}
      />
    ) : view === ViewsNames.AddNewUser ? (
      <AddNewUser
        onChangeSetPhone={writePhoneNumber}
        userData={addedUser}
        onWriteUserData={handleSetAddedUser}
        onSetVeiew={handleSetView}
      />
    ) : view === ViewsNames.SendQr && ticketSuccess ? (
      <SendQr
        ticket={ticketUrl}
        userData={addedUser}
        onSetView={handleSetView}
      />
    ) : (
      <AddUserAccess
        addParticipantStatus={addedPaticipantSuccess}
        onAddParticipant={onSubmitAddParticipant}
        onSetView={handleSetView}
        userData={{ ...addedUser, event_id: currentEventData?.id as number }}
      />
    )

  const checkIsLoading = () =>
    addedPaticipantLoad || ticketIsLoad ? (
      <LocationSpinner />
    ) : (
      renderCurrentView()
    )

  const writePhoneNumber = (phoneNumber: string) => {
    setAddedUser({ ...addedUser, phone_number: phoneNumber })
  }

  const renderSubtitle = () =>
    view === "SearchUser"
      ? currentEventData?.title
      : view === "AddNewUser"
      ? "Введите данные"
      : null

  const handleClosedPopup = () => {
    dispatch(setPromotionPopup(null))
    setView(ViewsNames.SearchUser)
    unlockBodyScroll()
  }

  const unlockBodyScroll = () => {
    document.body.style.overflow = "auto"
    document.body.style.height = "auto"
  }

  return createPortal(
    <div
      className={s.modal_overlay}
      style={{ display: currentEventData ? "flex" : "none" }}
    >
      <div className={s.modal_body}>
        <BtnClose
          className={s.modal_close}
          onClick={() => handleClosedPopup()}
        />
        <div className={s.modal_guts}>
          <div className={s.modal_header}>
            <h2 className={s.modal_header_title}>
              {AddToBaseModalTitles[view]}
            </h2>
            {renderSubtitle() && (
              <div className={s.modal_header_subtitle}>{renderSubtitle()}</div>
            )}
          </div>
          <div className={s.modal_content}>{checkIsLoading()}</div>
        </div>
      </div>
    </div>,
    document.body,
  )
}

export default AddToBaseModal
