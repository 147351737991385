import { FC } from "react"
import { usePromotionEvents } from "../../../shared/user-profile/hooks"
import ProfileEventCard from "../profile_event_card"

const PromotionEventList: FC = () => {
  const { isLoading, error, state } = usePromotionEvents()
  if (isLoading) return <div>Loading...</div>
  if (error) return <div>Error: {error.error}</div>
  return (
    <>
      {state?.map((e) => (
        <ProfileEventCard isPromotion={true} info={e} key={e.id} />
      ))}
    </>
  )
}

export default PromotionEventList
