import { useEffect } from "react"
import { Avatar } from "@mui/material"
import { ViewsNames } from "../.."
import { UserDataForAddToBaseParticipant } from "../../../../../app/data/promotion/promotionApi"
import GradientButton, {
  GradienButtonStyles,
} from "../../../../GradientStateBtn"
import AvatarFallback from "../../../../../assets/svg/header-icons-pack/avatar-empty-icon.svg?react"
import s from "./styles.module.scss"

type AddUserAccessProps = {
  userData: UserDataForAddToBaseParticipant
  onSetView: (view: ViewsNames) => void
  onAddParticipant: (userData: UserDataForAddToBaseParticipant) => void
  addParticipantStatus: boolean
}

const AddUserAccess = ({
  userData,
  onSetView,
  onAddParticipant,
  addParticipantStatus,
}: AddUserAccessProps) => {
  const { lastname, phone_number, firstname, telegram_username } = userData

  useEffect(() => {
    addParticipantStatus && onClickBtnGoToView(ViewsNames.SendQr)
  }, [addParticipantStatus])

  const onAddButtonAddParticipatn = async () => {
    onAddParticipant(userData)
  }

  const onClickBtnGoToView = (view: ViewsNames) => {
    onSetView(view)
  }
  return (
    <div className={s.add_access_user}>
      <div className={s.user_data}>
        <Avatar
          //src={participant.avatar}
          sx={{
            width: "100px",
            height: "100px",
            borderRadius: "50%",
          }}
        >
          <AvatarFallback className={s.user_avatar} />
        </Avatar>
        <h4 className={s.user_name}>{`${firstname} ${lastname}`}</h4>
        <p className={s.user_phone}>{phone_number}</p>
      </div>
      <div className={s.buttons_block}>
        <GradientButton<Object>
          onAction={() => onClickBtnGoToView(ViewsNames.AddNewUser)}
          style={GradienButtonStyles.borderGradient}
          params={{ text: "Назад", disabled: false }}
        />
        <GradientButton<Object>
          onAction={() => onAddButtonAddParticipatn()}
          style={GradienButtonStyles.fullGradient}
          params={{ text: "Добавить", disabled: false }}
        />
      </div>
    </div>
  )
}

export default AddUserAccess
