import { ChangeEvent, useState } from "react"
import { IMaskInput } from "react-imask"
import { PotentiaParticipantUserPreData, ViewsNames } from "../.."
import { UserDataForAddToBaseParticipant } from "../../../../../app/data/promotion/promotionApi"
import GradientButton, {
  GradienButtonStyles,
} from "../../../../GradientStateBtn"
import validation from "./validation"
import s from "./styles.module.scss"

type AddNewUserProps = {
  userData: Omit<
    UserDataForAddToBaseParticipant,
    "telegramUsername" | "eventId"
  >
  onSetVeiew: (popupVeiew: ViewsNames) => void
  onWriteUserData: (
    inputName: keyof PotentiaParticipantUserPreData,
    targetValue: string,
  ) => void
  onChangeSetPhone: (phoneNumbers: string) => void
}

const inputMaskPhone = "+{7}(000)000-00-00"

const AddNewUser = ({
  userData,
  onSetVeiew,
  onWriteUserData,
  onChangeSetPhone,
}: AddNewUserProps) => {
  const { firstname, phone_number, lastname, telegram_username } = userData
  const [isValid, setIsValid] = useState({ firstname: true, lastname: true })

  const onClickBtnGoToView = (view: ViewsNames) => {
    onSetVeiew(view)
  }

  const onSetPhone = (evt: React.FormEvent<HTMLInputElement>) => {
    onChangeSetPhone(evt.currentTarget.value)
  }

  const setIsDisabledBtn = () => {
    if (
      !/^\d{1,3}\(\d{3}\)\d{3}-\d{2}-\d{2}$/.test(phone_number.slice(1)) ||
      firstname === "" ||
      lastname === ""
    ) {
      return true
    } else return false
  }

  const onChangeWriteUserData = (
    inputName: keyof PotentiaParticipantUserPreData,
    evt: ChangeEvent<HTMLInputElement>,
  ) => {
    if (evt.target.value === "") {
      onWriteUserData(inputName, evt.target.value)
    }

    if (validation(evt.target.value, "OnlyLetters")) {
      onWriteUserData(inputName, evt.target.value)
      isValidStyling(inputName, true)
    } else {
      isValidStyling(inputName, false)
    }
  }

  const isValidStyling = (inputName: string, thisValid: boolean) => {
    switch (inputName) {
      case "firstname":
        return setIsValid({ ...isValid, firstname: thisValid })
      case "lastname":
        return setIsValid({ ...isValid, lastname: thisValid })
    }
  }

  return (
    <form
      action=""
      className={`${s.add_new_user_container} ${s.form}`}
      onSubmit={(e) => e.preventDefault()}
    >
      <ul className={s.add_new_user}>
        <li className={s.form__item}>
          <input
            className={s.form__input}
            placeholder="Имя"
            value={firstname}
            onChange={(e) => onChangeWriteUserData("firstname", e)}
          />
          <label hidden={isValid.firstname} className={s.form__label}>
            Только буквы*
          </label>
        </li>
        <div className={s.form__item}>
          <input
            className={s.form__input}
            placeholder="Фамилия"
            value={lastname}
            onChange={(e) => onChangeWriteUserData("lastname", e)}
          />
          <label hidden={isValid.lastname} className={s.form__label}>
            Только буквы*
          </label>
        </div>
        <div className={s.form__item}>
          <IMaskInput
            mask={inputMaskPhone}
            className={s.form__input}
            value={phone_number}
            onChange={(e) => onSetPhone(e)}
            placeholder="Номер телефона"
          />
        </div>
        <div className={s.form__item}>
          <input
            className={s.form__input}
            value={telegram_username}
            placeholder="@Telegram"
            onChange={(e) => onChangeWriteUserData("telegram_username", e)}
          />
        </div>
      </ul>
      <div className={s.buttons_block}>
        <GradientButton<Object>
          onAction={() => onClickBtnGoToView(ViewsNames.SearchUser)}
          style={GradienButtonStyles.borderGradient}
          params={{ text: "Назад", disabled: false }}
        />
        <GradientButton<Object>
          onAction={() => onClickBtnGoToView(ViewsNames.AccessNewUser)}
          style={GradienButtonStyles.fullGradient}
          params={{
            text: "Далее",
            disabled: setIsDisabledBtn(),
          }}
        />
      </div>
    </form>
  )
}

export default AddNewUser
