import { useState } from "react"
import { Avatar } from "@mui/material"
import { UserDataForAddToBaseParticipant } from "../../../../../app/data/promotion/promotionApi"
import { ViewsNames } from "../.."
import GradientButton, {
  GradienButtonStyles,
} from "../../../../GradientStateBtn"
import AvatarFallback from "../../../../../assets/svg/header-icons-pack/avatar-empty-icon.svg?react"
import Telegram from "../../../../../assets/svg/socials-icons/Telegram.svg?react"
import Whatsapp from "../../../../../assets/svg/socials-icons/WhatsApp.svg?react"
import QrCode from "../../../../../assets/Qrcode.svg?react"
import s from "./styles.module.scss"

type SendQrProps = {
  ticket: undefined | string
  userData: UserDataForAddToBaseParticipant
  onSetView: (popupVeiew: ViewsNames) => void
}

enum Messengers {
  Telegram = "Telegram",
  Watsapp = "Watsapp",
}

const SendQr = ({ onSetView, userData, ticket }: SendQrProps) => {
  const { firstname, phone_number, lastname, telegram_username } = userData
  const [messenger, setMessenger] = useState<Messengers | null>(null)

  const onClickBtnGoToView = (view: ViewsNames) => {
    onSetView(view)
  }

  const sendInMessenger = () => {
    const parsTicket = Object.values(ticket as string)[0]
    messenger === Messengers.Telegram
      ? window.open(`https://t.me/
			${telegram_username}?text=${parsTicket}`)
      : window.open(`https://wa.me/${phone_number}?text=${parsTicket}`)
  }

  return (
    <div className={s.send_qr}>
      <div>
        <div className={s.user_data}>
          <Avatar
            //src={participant.avatar}
            sx={{
              width: "100px",
              height: "100px",
              borderRadius: "50%",
            }}
          >
            <AvatarFallback className={s.user_avatar} />
          </Avatar>
          <h4 className={s.user_name}>{`${firstname} ${lastname}`}</h4>
          <p className={s.user_phone}>{phone_number}</p>
        </div>
        <div className={s.socials}>
          <h4 className={s.socials_title}>Выберите куда отправить:</h4>
          <div className={s.socials_icons_block}>
            <div className={s.icon_item}>
              <input
                className={s.socials_icon}
                type="radio"
                id="telegram"
                name="socials"
              />
              <label
                htmlFor="telegram"
                onClick={() => setMessenger(Messengers.Telegram)}
              >
                <Telegram />
              </label>
            </div>
            <div className={s.icon_item}>
              <input
                className={s.socials_icon}
                type="radio"
                id="whatsapp"
                name="socials"
              />
              <label
                htmlFor="whatsapp"
                onClick={() => setMessenger(Messengers.Watsapp)}
              >
                <Whatsapp />
              </label>
            </div>
          </div>
          <div className={s.qr_code_wrapper}>
            <QrCode className={s.qr_code} />
          </div>
        </div>
      </div>

      <div className={s.buttons_block}>
        <GradientButton<Object>
          onAction={() => onClickBtnGoToView(ViewsNames.AddNewUser)}
          style={GradienButtonStyles.borderGradient}
          params={{ text: "Назад", disabled: false }}
        />
        <GradientButton<Object>
          onAction={() => sendInMessenger()}
          style={GradienButtonStyles.fullGradient}
          params={{
            text: "Отправить QR-код",
            disabled: !messenger && true,
          }}
        />
      </div>
    </div>
  )
}

export default SendQr
